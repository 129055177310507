<template>

    <div class="card card-custom">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="d-flex justify-content-between col-12">
              <div class="form-group">
                <div style="margin-left: 5px;margin-right: 5px;">
                  <input :id="'inputImportExcel'+innerId" type="file" ref="fileField" class="inputfile" v-on:change="sendRequestData" :disabled="disableLoadingButton">
                  <label :for="'inputImportExcel'+innerId" :class="btnClass" :disabled="disableLoadingButton">
                    <i class="fa fa-file-import"></i>
                    {{ placeholder }}
                    <span v-if="disableLoadingButton"><i class="fa fa-spinner fa-pulse fa-fw"></i></span>
                  </label>
                </div>

              </div>

              <div>
                <div>
                  <b-button class="mt-auto mr-2" variant="primary" @click="getFileTemplate">
                    <i v-if="is_requesting" class="fa fa-spinner fa-pulse fa-fw"></i>
                    <i v-else class="fas fa-file-export"></i>
                    {{ $t('export_template') }}
                  </b-button>
                </div>
              </div>
            </div>


            <div class="form-group col-md-12 mt-5">
              <h5>{{ $t('import_status') }}</h5>
            </div>
            <div class="form-group col-md-12 mt-5">
              <b-alert v-if="alter_message" show variant="danger">{{ alter_message }}</b-alert>
              <b-alert v-if="process_message" show variant="warning">{{ process_message }}</b-alert>
            </div>
            <div class="form-group col-md-12 mt-5" v-if="errors_list.length > 0">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th>{{ $t('items.id') }}</th>
                  <th>{{ $t('items.sku') }}</th>
                  <th>{{ $t('items.qty') }}</th>
                  <th>{{ $t('export_import.attribute') }}</th>
<!--                  <th>{{ $t('export_import.row') }}</th>-->
                </tr>
                </thead>
                <tbody>
                <tr v-for="(error, index) in errors_list" :key="index">
                  <td>{{ error.errors ? error.errors.ItemNumber : '' }}</td>
                  <td>{{ error.errors ? error.errors.sku_code : '' }}</td>
                  <td>{{ error.errors ? error.errors.quantity : '' }}</td>
                  <td>{{ error.attribute ? error.attribute : '' }}</td>
<!--                  <td>{{ error.row ? error.row : '' }}</td>-->
                </tr>
                </tbody>
              </table>
            </div>
            <div class="form-group col-md-12 mt-5" v-else>
              <p class="text-center">{{ $t('no_errors') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "Index",
  data() {
    return {
      mainRouteDependency: 'base/dependency',
      mainRoute: 'items/item-open-stock/import_items',
      dir_upload: null,
      reloadUploadAttachment: true,
      file_url: null,
      is_requesting: false,
      errors_list: [],
      inventory_id: this.$route.query.inventory_id ? this.$route.query.inventory_id : null,
      open_stock_id: this.$route.query.open_stock_id ? this.$route.query.open_stock_id : null,

      alter_message: '',
      innerId: '1',
      placeholder: this.$t('import_data'),
      disableLoadingButton: false,
      btnClass: 'btn btn-primary btn-sm',
      process_message: '',
      process_status: '',
    }
  },
  methods: {
    listenerAttachment() {

    },
    listenerErrors(errors) {
      this.errors_list = errors ? errors : [];
    },
    resetAll() {
      this.$refs.fileField.value = null;
      this.file = null;
      this.alter_message = '';
      this.process_message = this.$t('the_data_import_process_is_in_progress');

    },
    reloadFile(){
      this.errors_list = [];
      this.process_message = '';
    },
    sendRequestData() {
      this.reloadFile();
      let files = document.getElementById('inputImportExcel' + this.innerId).files;
      this.alter_message = '';
      this.file = null;
      let filename = files[0].name;
      let extension = filename.substring(filename.lastIndexOf(".")).toUpperCase();
      if (extension == '.CSV') {
        this.file = files[0];
      } else {
        this.alter_message = this.$t('please_select_a_valid_csv_file');
        return false;
      }

      /**
       * send data request
       * @type {FormData}
       */

      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('inventory_id', this.inventory_id);
      formData.append('open_stock_id', this.open_stock_id);

      ApiService.postFormData(this.mainRoute,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then((response) => {
        this.$successAlert(response.data.message);
        Vue.prototype.$postStatus = true;
      }).catch((error) => {
        this.resetAll();
        if (error.response && error.response.data){
          this.process_message = error.response.data.message;
          this.errors_list = error.response.data.errors;
        }
        // this.$errorAlert(error);
        Vue.prototype.$postStatus = true;
      });

    },

    getFileTemplate() {
      this.is_requesting = true;
      ApiService.get(this.mainRouteDependency + "/template/item_open_stock").then((response) => {
        this.is_requesting = false;
        if (response.data.data.link)
          window.open(response.data.data.link, '_blank');
      }).catch((errors) => {
        this.listenerErrors(errors);
        this.is_requesting = false;
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.import_quantities")}]);
  },
}
</script>

<style scoped>

</style>